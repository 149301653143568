.abs-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-center-y {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-center-x {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-ellipses(@number_of_lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: @number_of_lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
