@import "../../../styles/mixins.less";

.loading__fallback {
  height: 100vh;
  .flex-column-center;

  img {
    display: block;
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    animation: blinker 1.5s linear infinite;
  }

  h3 {
    text-align: center;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;