@import "../../styles/variables.less";
@import "../../styles/mixins.less";

.not__found {
  height: 100vh;
  .flex-column-center;

  &__img {
    display: block;
    width: 25rem;
  }

  &__title {
    margin: 2rem 0 0.5rem 0;
  }

  &__text {
    width: 25%;
    text-align: center;
    margin-bottom: 2rem;
    opacity: 0.8;
  }
}

@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;