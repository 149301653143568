@import "../../../../styles/variables.less";
@import "../../../../styles/mixins.less";

.confirm__vector {
  .flex-column-center;
  position: relative;

  &__circle {
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    background-image: linear-gradient(
      156deg,
      rgba(90, 2, 184, 0.46) -21%,
      rgba(90, 2, 184, 0) 74%
    );
  }

  &__square {
    width: 23rem;
    height: 8rem;
    padding: 1rem 2rem;
    position: absolute;
    border-radius: 2px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 54px 0 rgba(82, 92, 102, 0.12);
    background-color: rgba(255, 255, 255, 0.75);

    &__line {
      width: 2rem;
      height: 4px;
      border-radius: 3px;
      background-color: @color-light-gray;

      &.--small {
        width: 1rem;
      }

      &.--long {
        width: 6rem;
      }
    }

    &__row {
      margin-top: 1.5rem;
    }

    &__box {
      width: 1.7rem;
      height: 1.7rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      .flex-row-center;

      &__dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: @color-success;
      }
    }

    &__index {
      position: absolute;
      top: -1rem;
      right: 2rem;
      width: 2.2rem;
      height: 2.2rem;
      background-color: @color-secondary;
      border-radius: 50%;
      color: @color-white;
      font-size: 0.8rem;
      font-family: @font-bold;
      border: 3px solid @color-white;
      .flex-row-center;
    }
  }
}
@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;