.logo__container {
  width: 14rem;
  height: 2rem;
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
  }

  .logo__lg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;

    @media screen and (min-width: 575px) {
      display: block;
    }
  }

  .logo__sm {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: none;

    @media screen and (max-width: 575px) {
      display: block;
    }
  }

  @media screen and (max-width: 575px) {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: auto;
  }

  @media screen and (max-width: 320px) {
    width: 3rem;
    height: 3rem;
  }
}

@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;