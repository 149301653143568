@import "~antd/dist/antd.less";
@import "./variables.less";

@font-face {
  font-family: "GilroyLight";
  src: local("GilroyLight"),
    url("../assets/fonts/Gilroy-Light.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url("../assets/fonts/Gilroy-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroySemibold";
  src: local("GilroySemibold"),
    url("../assets/fonts/Gilroy-Semibold.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
    url("../assets/fonts/Gilroy-Bold.otf") format("opentype");
  font-weight: normal;
}

h1,
h2,
h3 {
  font-family: @font-bold;
}

h4,
h5,
h6 {
  font-family: @font-semibold;
}

p {
  margin: 0;
}

.ant-btn-lg {
  padding: 5px 15px;
  font-size: 0.9rem !important;
}

th {
  font-family: @font-semibold;
}

// .ant-menu-item a {
//   font-family: @font-semibold;
// }

.ant-upload.ant-upload-select {
  display: block;
}

.ant-menu-item-group-title {
  color: @color-gray-4;
  font-size: 0.76rem;
  font-family: @font-semibold;
  margin-top: 1rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid @color-light-gray-2;
}

.ant-upload-list-item-thumbnail {
  > img {
    object-fit: cover;
  }
}

.anticon {
  &.--xxl {
    font-size: 1.8rem;
  }

  &.--xl {
    font-size: 1.3rem;
  }

  &.--lg {
    font-size: 1.2rem;
  }

  &.--md {
    font-size: 1rem;
  }

  &.--sm {
    font-size: 0.8rem;
  }
}

@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;