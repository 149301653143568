@import "../../../../styles/variables.less";
@import "../../../../styles/mixins.less";

.auth__vector {
  .flex-column-center;
  position: relative;

  &__circle {
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    background-image: linear-gradient(
      156deg,
      rgba(90, 2, 184, 0.46) -21%,
      rgba(90, 2, 184, 0) 74%
    );
  }

  &__square {
    width: 19rem;
    height: 19.5rem;
    position: absolute;
    border-radius: 2px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 54px 0 rgba(82, 92, 102, 0.12);
    background-color: rgba(255, 255, 255, 0.75);

    &__logo {
      width: 2rem;
      height: 2rem;
      display: block;
      margin: 2rem auto 3rem auto;
    }

    &__icon {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin: 2rem auto 2.5rem auto;
      background-color: @color-primary;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        opacity: 0.05;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: @color-primary;
      }

      &::after {
        content: "";
        position: absolute;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        opacity: 0.05;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: @color-primary;
      }

      img {
        width: 60%;
        height: 60%;
        object-fit: cover;
      }
    }

    &__line {
      width: 60%;
      height: 0.25rem;
      border-radius: 2px;
      margin: 0 auto;
      background-color: @color-light-gray;

      &.--small {
        width: 40%;
        margin: 1rem auto 0 auto;
        background-color: @color-light-gray;
        opacity: 0.4;
      }
    }

    &__text {
      text-align: center;
      margin-top: 1.5rem;
    }

    &__row {
      margin-top: 2rem;
    }

    &__box {
      width: 1.7rem;
      height: 1.7rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      .flex-row-center;

      &__dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: @color-success;
      }
    }
  }
}

@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;