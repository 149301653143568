@import "../../styles/variables.less";
@import "../../styles/mixins.less";

.ev {
  height: 100vh;

  &__content {
    height: 100vh;

    &__row {
      margin-top: 4rem;

      @media screen and (max-width: 575px) {
        margin-top: 2rem;
      }
    }

    &__heading {
      font-size: 2rem;
      line-height: 2ch;

      @media screen and (max-width: 320px) {
        font-size: 1.6rem;
      }
    }

    &__text {
      width: 80%;
      font-size: 0.9rem;
      opacity: 0.7;

      &.--space-bottom {
        margin-bottom: 2rem;
      }
    }

    &__link {
      font-size: 0.8rem;
      opacity: 0.85;
      margin-bottom: 1rem;
    }

    &__code {
      margin: 4rem 0;

      &__label {
        margin-bottom: 1rem;
        font-size: 0.9rem;
      }

      &__input input {
        font-family: @font-semibold;
      }

      &__text {
        font-size: 0.85rem;
        margin-top: 1rem;
        opacity: 0.8;

        span {
          color: @color-primary;
          text-decoration: underline;
          cursor: pointer;
          font-family: @font-semibold;
        }
      }
    }
  }

  &__banner {
    height: 100vh;
    position: relative;
    background-color: @color-light-primary !important;

    @media screen and (max-width: 1250px) {
      display: none;
    }

    &__text {
      opacity: 0.7;
      margin: 0;
    }

    &__container {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }

  &__link {
    display: block;
    margin-top: 1.5rem;
    text-align: center;
    color: @color-gray;
  }
}

@primary-color: #5a02b8;@font-family: GilroyRegular, sans-serif;@card-radius: 5px;@avatar-border-radius: 12px;@menu-item-color: #808b95;@layout-header-background: #f6f7f8;@layout-footer-background: #f6f7f8;